.title {
    padding-right: 64px;
    margin-bottom: 16px;
    overflow-wrap: break-word;
    hyphens: auto;
}

.text {
    opacity: 0.5;
    margin-bottom: 40px;
}

.uploadBox {
    padding: 40px;
    border: 1px dashed rgba(#010101, 0.2);
    text-align: center;
    position: relative;
    h4 {
        text-decoration: underline;
        font-weight: 400;
    }
    p {
        opacity: 0.5;
        word-break: break-all;
    }
    i {
        font-size: 24px;
        position: absolute;
        top: 16px;
        right: 16px;
    }

    &:hover {
        cursor: pointer;
    }
    &.error {
        border-color: #ff4c4c;
    }
}

.wrongFormat {
    color: #ff4c4c;
    margin-top: 8px;
    text-align: right;
}

.financialProof {
    button {
        margin-top: 40px;
        &:disabled {
            opacity: 0.5;
        }
    }
}
