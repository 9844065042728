@import '~assets/scss/variables.scss';

.input {
    display: none;
}

.wrapper {
    display: flex;
    align-items: center;
    cursor: pointer;
    &.is-disabled {
        cursor: default;
    }
}

.root {
    position: relative;
    height: 28px;
    width: 28px;
    border: 1px solid var(--color-forms-border);
    transition: background-color 150ms linear;

    &::before {
        content: '';
        position: absolute;
        width: 15px;
        height: 8px;
        top: 7px;
        left: 5px;
        border-bottom: var(--color-background-primary) 2px solid;
        border-left: var(--color-background-primary) 2px solid;
        opacity: 0;
        transition: opacity 150ms linear;
        transform: rotate(-45deg);
    }
}

.input:checked + .root {
    background-color: var(--color-buttons-primary);

    &::before {
        opacity: 1;
    }
}

.label {
    margin-left: 12px;
    color: #768292;
}
