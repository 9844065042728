@import "~assets/scss/mixins.scss";

.root {
  max-width: 180px;
  height: auto;
  display: block;
  cursor: pointer;
  max-height: 96px;
  @include breakpoint("tablet") {
    max-height: 80px;
  }
  @include breakpoint("mobile") {
    max-height: 62px;
  }
}
