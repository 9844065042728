@import '~assets/scss/mixins.scss';

.section{
  padding: 200px 0 100px;
}
.title {
  margin-bottom: 56px;
  &::after {
    content: '';
    display: block;
    width: 80px;
    height: 8px;
    background-color: var(--color-buttons-primary);
    margin-top: 16px;
  }
}
.row{
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(0,0,0,.1);
  padding: 16px 0;
}
.rowDeclined{
  color: #808080;
}
.tableTitle{
  font-weight: 700;
}
.removeIcon{
  font-size: 20px;
  color: #808080;
  cursor: pointer;
  display: inline-block;
  line-height: 1;
  transition: .3s;
  &:before{
    vertical-align: middle;
  }
  &:hover{
    color: #000;
  }
}
.object{
  width: 35%;
}
.document{
  width: 25%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 10px;
}
.status{
  width: 14%;
  text-transform: lowercase;
  &:first-letter{
    text-transform: uppercase;
  }
}
.date{
  width: 17%;
}
.remove{
  width: 9%;
  text-align: right;
}

.deleted,
.declined{
  color: #FF525C;
}
.pending{
  color: #F2A844;
}
.accepted{
  color: #5ACB84;
}
.modal{
  text-align: center;
}
.modalTitle{
  margin-bottom: 40px;
}
.modalIcon{
  font-size: 74px;
  line-height: 1;
  margin-bottom: 32px;
  color: #F2A844;
  display: inline-block;
}
.actions{
  display: flex;
  justify-content: space-evenly;
  margin: 0 -10px;
  button{
    margin: 0 10px;
  }
}
.outlineButton{
  color: #010101;
  background-color: transparent;
  border: 1px solid #010101;
}
@include breakpoint('mobile'){
  .section{
    padding-top: 120px;
    font-size: 14px;
    line-height: 20px;
  }
  .title{
    margin-bottom: 24px;
    &::after{
      width: 40px;
      height: 4px;
      margin-top: 8px;
    }
  }
  .row{
    flex-wrap: wrap;
  }
  .infoList{
      border-top: 1px solid rgba(0,0,0,.1);
  }
  .titles{
    display: none;
  }
  .object{
    order: 4;
    width: 100%;
    padding: 10px 0 8px;
  }
  .document{
    order: 5;
    width: 100%;
  }
  .status{
    width: 50%;
    order: 1;
  }
  .date{
    order: 2;
    text-align: right;
    width: 40%;
  }
  .remove{
    width: 10%;
    order: 3;
  }
}