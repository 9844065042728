@import "~assets/scss/shared.scss";

.root {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  font-weight: 700;
}

.left,
.right {
  width: 40px;
  height: 40px;
  cursor: default;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.5;
  margin: 0 14px;
  @extend %shared-hover-transition;
  &.is-active {
    opacity: 1;
    cursor: pointer;
  }
}

.right-icon {
  width: 0;
  height: 0;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-left: 6px solid #000;
}

.left-icon {
  width: 0;
  height: 0;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-right: 6px solid #000;
}

.number,
.dot {
  width: 17px;
  text-align: center;
  opacity: 0.5;
  font-size: 14px;
  margin: 0 6px;
}

.number {
  cursor: pointer;
  @extend %shared-hover-transition;
  &.is-active {
    cursor: default;
    opacity: 1;
  }
}
