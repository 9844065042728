.root {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f2f2f2;
  opacity: 0;
  visibility: hidden;
  transition: all 400ms ease-in-out 300ms;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 30%;
  font-family: Manrope, Helvetica, sans-serif;
  font-weight: 700;
  &.is-open {
    opacity: 1;
    visibility: visible;
  }
}

.menu {
  li {
    margin-top: 18px;
    text-transform: uppercase;
    font-size: 14px;
    white-space: nowrap;
    &:first-child {
      margin-top: 0;
    }
  }
}

.switcher {
  margin-top: 27px;
  max-width: 44px;
}

.spacer {
  margin-left: -46%;
  margin-right: -46%;
  height: 1px;
  background-color: #000;
  margin-top: 32px;
  margin-bottom: 32px;
}

.login {
  margin-bottom: 28px;
  text-transform: uppercase;
}
