@import '~assets/scss/mixins';

.hero {
    height: 800px;
    display: flex;
    position: relative;
    width: 100%;
    max-width: 1170px;
    margin-left: auto;
    margin-right: auto;
}
.heroText {
    padding: 120px 0 120px 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    max-width: 335px;
    width: 335px;
    z-index: 1;
    background-color: white;
    h3 {
        margin-bottom: 24px;
        font-weight: 700;
        color: var(--color-buttons-primary);
    }
    h1 {
        margin-bottom: 40px;
        max-width: 720px;
        width: 720px;
        font-size: 80px;
        line-height: 80px;
    }
}
.heroButton {
    height: unset;
    padding: 20px 80px;
    width: auto;
    font-size: 14px;
    text-transform: uppercase;
}
.heroImage {
    position: relative;
    width: calc(100vw - 335px);
    height: 800px;
    max-height: 800px;
    overflow-y: clip;
    .slider {
        position: relative;
        z-index: -1;
    }
    img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        min-height: 800px;
        z-index: 0;
    }

    @media screen and (min-width: 1170px) {
        width: calc(835px + ((100vw - 1170px) / 2));

        :global(.slick-list) {
            width: calc(835px + ((100vw - 1170px) / 2));
        }
    }
}
.actions {
    color: var(--color-buttons-primary);
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;

    > div {
        width: 120px;
        height: 120px;
        font-size: 24px;
        line-height: 24px;
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 150ms ease-in-out;
        &:hover {
            background: var(--color-buttons-primary);
            color: white;
            cursor: pointer;
        }
    }
}
.backdrop {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 100%);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
}

@include breakpoint('mobile') {
    .hero {
        height: unset;
        flex-direction: column;
    }

    .heroText {
        order: 2;
        padding: 32px 24px 24px;
        width: 100%;
        max-width: 100%;

        h1 {
            font-size: 32px;
            line-height: 40px;
            max-width: 100%;
            width: 100%;
            margin-bottom: 24px;
        }

        h3 {
            font-size: 20px;
            margin-bottom: 16px;
        }
        .invertedText {
            h1 {
                min-height: auto;
            }
        }
    }
    .heroImage {
        width: 100%;
        height: 400px;
        img {
            min-height: 400px;
        }
    }
    .actions {
        > div {
            width: 64px;
            height: 64px;
            font-size: 22px;
            line-height: 22px;
        }
    }
}
.imageSlider {
    width: 100% !important;
    :global(.slick-list) {
        width: 100% !important;
    }
}
