@import "~assets/scss/variables.scss";

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  text-align: center;
}

.sub-title {
  font-weight: 500;
  margin-top: 17px;
  text-align: center;
}

.icon {
  font-size: 96px;
  margin-bottom: 40px;
  color: #000;
}

.button {
  margin-top: 30px;
  max-width: 250px;
  padding-top: 27px !important;
  padding-bottom: 27px !important;
}

.button-links {
  font-weight: 500;
  margin-top: 24px;
  font-size: 16px;
  span {
    padding: 0 4px;
  }
}

.text {
  opacity: 0.35;
}

.success {
  color: $successTraditionTemplate;
}

.link {
  cursor: pointer;
  text-decoration: underline;
  color: var(--color-buttons-textLink);
  &:hover {
    text-decoration: none;
  }
}
