@import '~assets/scss/mixins.scss';

.title {
    font-family: var(--typography-heading-fontFamily);
    color: var(--color-text-heading);
    //&.is-traditional {
    //  //font-family: YesevaOne, Helvetica, sans-serif;
    //  font-weight: normal;
    //}
    //&.is-modern {
    //  //font-family: Manrope, Helvetica, sans-serif;
    //  font-weight: 800;
    //}
    //&.is-funky,
    //&.is-minimal {
    //  //font-family: GtWalsheim, Helvetica, sans-serif;
    //  font-weight: bold;
    //}
    //&.is-classic {
    //  //font-family: MyanmarMN, Helvetica, sans-serif;
    //  font-weight: 700;
    //}
}

h1.title,
h2.title {
    @include font-size(var(--typography-heading-fontSizes-h1));
    line-height: 1.16;
}

h3.title {
    @include font-size(var(--typography-heading-fontSizes-h2));
    line-height: 1.25;
}

h4.title {
    @include font-size(var(--typography-heading-fontSizes-h3));
    line-height: 1.33;
}

@include breakpoint('mobile') {
    h1.title,
    h2.title {
        font-size: 32px;
        line-height: 40px;
    }
    h3.title {
        font-size: 24px;
        line-height: 32px;
    }
}
//h1.title,
//h2.title {
//  font-size: 48px;
//  line-height: 56px;
//
//  @include breakpoint("mobile") {
//    font-size: 32px;
//    line-height: 40px;
//  }
//}

//h3.title {
//  font-size: 32px;
//  line-height: 40px;
//  background-color: pink;
//  @include breakpoint("mobile") {
//    font-size: 24px;
//    line-height: 32px;
//  }
//}

h5.title {
    font-size: 24px;
    line-height: 32px;
}

h6.title {
    //&.is-traditional,
    //&.is-modern {
    //  font-family: Manrope, Helvetica, sans-serif;
    //  font-weight: bold;
    //}
    //&.is-funky,
    //&.is-minimal,
    //&.is-classic {
    //  font-family: GtWalsheim, Helvetica, sans-serif;
    //  font-weight: 500;
    //}
}

.text {
    //&.is-traditional,
    //&.is-modern {
    //  font-family: Manrope, Helvetica, sans-serif;
    //  font-weight: 200;
    //}
    //&.is-minimal,
    //&.is-funky,
    //&.is-classic {
    //  font-family: GtWalsheim, Helvetica, sans-serif;
    //  font-weight: 400;
    //}
    &.wysiwyg {
        p,
        ul {
            margin-bottom: 15px;
        }
        ul {
            list-style: initial;
            margin-left: 20px;
        }
        li {
            margin-top: 5px;
            &:first-child {
                margin-top: 0;
            }
        }
    }
}
