@import "~assets/scss/mixins.scss";

.section{
  padding: 50px 0;
}

.list{
  display: flex;
  justify-content: space-between;
}

.item {
  max-width: 320px;
  h4{
    margin-bottom: 12px;
  }
  .icon{
    color: var(--color-text-heading);
  }
}

.icon {
  font-size: 40px;
  margin-bottom: 43px;
  display: inline-block;
}

@include breakpoint("tablet") {
  .section{
    padding-top: 56px;
    padding-bottom: 32px;
  }
  .list{
    display: block;
    text-align: center;
  }
  .item{
    margin: 0 auto;
    &:not(:last-child){
      margin-bottom: 64px;
    }
  }
  .icon{
    margin-bottom: 35px;
  }
}