.root {
    display: flex;
    flex-direction: column;
    align-items: center;
    h2 {
        width: 100%;
        text-align: left;
        margin-bottom: 16px;
    }
}

.button {
  padding-top: 27px !important;
  padding-bottom: 27px !important;
  margin-top: 40px;
  // max-width: 200px;
  width: unset;
}

.bottom-text {
    margin-top: 24px;
    font-size: 16px;
}
