@import '~assets/scss/mixins.scss';

.root {
}

.separator {
    width: 100%;
    height: 1px;
    margin: 24px auto;
    background-color: var(--color-forms-border);
}

.line {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin: 0 -15px 32px;

    &.last-line {
        align-items: flex-end;
    }
}

.input-wrapper {
    padding: 0 15px;
    width: 50%;
    margin-top: 0;
}

.post-code {
    width: 36%;
}

.city {
    width: 64%;
}

.checkbox-wrapper {
    margin-left: 0;
}

.button-wrapper {
    flex: 1;
    text-align: right;
    margin-top: 56px;
    margin-right: 14px;
    display: flex;
    justify-content: flex-end;

    button {
        max-width: 246px;
        margin-bottom: 16px;
    }
}

.small-line {
    width: 50%;
    display: flex;
    padding: 0 7px;
    .input-wrapper {
        padding: 0 8px;
    }
}

.genders-options {
    display: flex;
    align-items: center;
    height: 64px;
    max-width: 430px;
}

.radio-wrapper {
    margin-top: 0;
    input[type='radio'] {
        height: auto;
    }
}

.error {
    position: absolute;
    top: 95%;
}

@include breakpoint('tablet') {
    .button-wrapper {
        justify-content: center;
    }
    .post-code {
        width: 42%;
    }
    .city {
        width: 58%;
    }
}

@include breakpoint('mobile') {
    .line {
        margin-bottom: 0;
    }
    .input-wrapper {
        margin-bottom: 32px;
    }
    .genders-options,
    .small-line,
    .line {
        display: block;
    }
    .small-line,
    .input-wrapper,
    .city,
    .post-code,
    .button-wrapper button {
        width: 100%;
    }

    .genders-options {
        height: auto;
    }

    .radio-wrapper {
        &:not(:last-child) {
            margin-bottom: 24px;
        }
    }
}
