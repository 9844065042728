@import "~assets/scss/mixins.scss";
.section{
  padding-top: 212px;
  padding-bottom: 100px;
}

.list {
  margin-left: -10px;
  margin-right: -10px;
  display: flex;
  flex-wrap: wrap;
}

.title{
  margin-bottom: 80px;
}

.pagination {
  margin-top: 30px;
  margin-bottom: 70px;
}
.empty-box{
  max-width: 320px;
  margin: 0 auto;
  text-align: center;
}
.icon-row{
  margin-bottom: 36px;
  i{
    font-size: 49px;
    color: var(--color-text-heading);
  }
}
.empty-title{
  margin-bottom: 12px;
}
.empty-text{
  margin-bottom: 36px;
  color: #000;
  opacity: .5;
}
.button{
  max-width: 278px;
  display: inline-flex;
}

@include breakpoint('tablet'){
  .section{
    padding: 104px 0 64px;
  }
  .title{
    margin-bottom: 24px;
  }
}


@include breakpoint('mobile'){
  .section{
    padding: 87px 0 48px;
  }
}