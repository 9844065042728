.textBox {
    position: relative;

    .blackText {
        overflow: hidden;
        h1 {
            position: relative;
            min-height: 120px;
            z-index: 1;
        }
    }
    .whiteText {
        position: absolute;
        top: 0;
        color: white;
        z-index: 0;
    }
}
