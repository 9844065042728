@import '~assets/scss/mixins.scss';

.title {
    margin-top: 35px;
    text-align: center;
}

.timer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 24px !important;
}
.number {
}

.value {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    position: relative;
    text-align: center;
    min-width: 58px;
    font-weight: 700;
    font-size: 24px;
    padding: 3px 14px;

    &:after,
    &:before {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border-top: 4px solid transparent;
        border-bottom: 4px solid transparent;
        top: 50%;
        transform: translateY(-50%);
        z-index: 3;
    }
    &:after {
        border-right: 6px solid var(--color-background-secondary);
        right: 0;
    }
    &:before {
        border-left: 6px solid var(--color-background-secondary);
        left: 0;
    }
    &.is-year {
        width: 76px;
        background: rgba(#ffffff, 0.3);
    }
}
.value {
    @include font-size(var(--typography-heading-fontSizes-h3));
    color: var(--color-text-heroAndNavigation);
}
.spacer {
    @include font-size(var(--typography-heading-fontSizes-h3));
    color: var(--color-text-heroAndNavigation);
    line-height: 50px;
    &.is_year {
        margin-right: 8px;
    }
    margin-bottom: 8px;
}

.spacer {
    align-self: flex-start;
    margin: 0 2px;
    line-height: 56px;
    font-size: 24px;
}

.unit {
    padding-top: 8px;
    text-align: center;
}

.is-big {
    .title {
        margin-bottom: 24px;
    }
    .value {
        width: 80px;
        font-weight: 800;
        font-size: 40px !important;
        min-height: 72px;
        max-height: 72px;
        min-width: 80px;
        max-width: 80px;
        &.is-year {
            width: 120px;
            max-width:120px;
        }
        &.is-wide {
            width: 110px;
        }
    }
    .value,
    .spacer {
        line-height: 72px;
        @include font-size(var(--typography-heading-fontSizes-h1));
    }
    .spacer {
        font-size: 24px;
        line-height: 68px;
    }
}

//@include breakpoint("mobile") {
//  &.is-big {
//    .title {
//      margin-bottom: 12px;
//    }
//    .value {
//      padding: 16px 0;
//      font-size: 24px;
//      width: 54px;
//    }
//    .spacer {
//      margin-top: 14px;
//    }
//  }
//}
