@import '~assets/scss/mixins.scss';

.page {
    display: flex;
    height: 100%;
    min-height: 100vh;
    flex-direction: column;
}

.logo-wrapper {
    padding-top: 26px;
    padding-bottom: 26px;
    text-align: center;
    img {
        display: inline-block;
        vertical-align: middle;
    }
}
.main {
    display: flex;
    flex: 1;
}

.left,
.right {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.left {
    img {
        max-width: 100%;
    }
}

.body {
    width: 100%;
    max-width: 490px;
}

.eye {
    position: absolute;
    top: 55px;
    right: 20px;
    font-size: 24px;
    cursor: pointer;
}

.password {
    padding-right: 67px;
}

.button {
    margin-top: 40px;
    max-width: 200px;
}

.login {
    margin-top: 24px;
    text-decoration: underline;
    cursor: pointer;
    color: var(--color-buttons-textLink);
    &:hover {
        text-decoration: none;
    }
}

@include breakpoint('tablet') {
    .page {
        padding-bottom: 40px;
    }
    .logo-wrapper {
        padding-top: 16px;
        padding-bottom: 33px;
    }
    .main {
        flex-direction: column;
        align-items: center;
    }
    .body {
        max-width: 530px;
    }
    .left,
    .right {
        width: 100%;
        max-width: 530px;
    }
    .left {
        margin-bottom: 10px;
        img {
            max-width: 278px;
        }
    }
    .right h2 {
        text-align: center;
    }
    .button {
        margin: 56px auto 0;
    }
    .login {
        margin-top: 32px;
        text-align: center;
    }
}

@include breakpoint('mobile') {
    .login {
        margin-top: 24px;
    }
    .left {
        img {
            max-width: 164px;
        }
    }
}
