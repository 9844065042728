@import '~assets/scss/shared.scss';
@import '~assets/scss/_mixins.scss';

.body {
    padding-top: 49px;
    display: flex;
    justify-content: space-between;
}

.description {
    width: 46%;
}

.bid-block {
    width: 51.5%;
    position: relative;
    z-index: 2;
    margin-top: -249px;
    padding: 56px 56px 0;
}

.image {
    height: 660px;
    width: 100%;
    object-fit: cover;
    display: block !important;
}

.slider-preview {
    display: flex;
}

.preview {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 16px;
    opacity: 0.6;
    @extend %shared-hover-transition;
    cursor: pointer;
    &.is-active {
        opacity: 1;
        cursor: default;
    }
}

.default-preview {
    width: 80px;
    height: 80px;
    border: 1px solid #e6e6e6;
    cursor: pointer !important;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

@include breakpoint('mobile') {
    .body {
        padding-bottom: 40px;
    }
    .image {
        height: 400px;
    }
    .body {
        display: block;
        padding-top: 24px;
    }
    .description {
        width: 100%;
    }
    .bid-block {
        margin-top: 24px;
        width: 100%;
        padding: 0;
    }
    .default-preview,
    .preview {
        width: 48px;
        height: 48px;
    }
    .preview {
        margin-right: 8px;
    }
}
