@import '~assets/scss/_mixins.scss';
.bid-block {
    background: rgba(255, 255, 255, 0.6);
    backdrop-filter: blur(80px);
}
.price {
    color: var(--color-buttons-primary);
    margin-bottom: 16px;
}
.title {
    margin-bottom: 16px;
}
.timer {
    margin-bottom: 40px;
    :global(.timer-boxes) {
        justify-content: flex-start;
    }
    :global(.number-value) {
        background-color: rgba(0, 0, 0, 0.05);
    }
    :global(.timer-title) {
        padding: 0 !important;
        background-color: transparent !important;
    }
}
@include breakpoint('mobile'){
    .timer{
        margin-bottom: 24px;
    }
}