@import '~assets/scss/mixins.scss';
@import '~assets/scss/shared.scss';

.section {
    padding-bottom: 100px;
    position: relative;
}

.success {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    z-index: 5;
    visibility: hidden;
    background-color: #f1f1f1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    @extend %shared-hover-transition;
    i {
        font-size: 96px;
        color: #000;
    }
    h4 {
        margin-top: 32px;
    }
    &.is-success {
        visibility: visible;
        opacity: 1;
    }
}

.triangle-right {
    width: 0;
    height: 0;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 9px solid #fff;
}

@include breakpoint('tablet') {
    .success {
        i {
            font-size: 48px;
        }
        h4 {
            margin-top: 40px;
        }
    }
}

@include breakpoint('mobile') {
    .success {
        h4 {
            margin-top: 24px;
            max-width: 290px;
            text-align: center;
        }
    }
}
