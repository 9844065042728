@import '~assets/scss/shared.scss';
@import '~assets/scss/variables.scss';

.select {
    display: block;
    width: 100%;
    font-weight: normal;
    line-height: 1;
    background-clip: padding-box;
    border: 1px solid;
    border-radius: 0;
    outline: none;
    font-family: var(--typography-paragraphBody-fontFamily);
    font-size: var(--typography-paragraphBody-fontSize);
    border-color: var(--color-forms-border);
    background-color: var(--color-forms-background);
    color: #000;
    &::placeholder {
        color: var(--color-forms-placeholder);
    }
    &.have-error {
        border: 1px solid $redPrimaryTraditionTemplate !important;
    }
    &.is-medium {
        height: 64px;
    }
    &.is-big {
        height: 72px;
    }
}
