.root {
  padding: 212px 20px;
  text-align: center;
}

.icon {
  font-size: 80px;
  margin-bottom: 20px;
  color: #000;
  display: inline-block;
}