.root {
  width: 40px;
  height: 40px;
  position: absolute;
  z-index: 500;
  top: 20px;
  left: 20px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  transition: background-color 400ms ease-out 400ms;
  &.is-open {
    background-color: #000;
    transition: background-color 400ms ease-in 400ms;
    .line {
      background-color: #fff;
      width: 18px;
      &:nth-child(1) {
        top: 19px;
        transform: rotate(45deg);
      }
      &:nth-child(2) {
        opacity: 0;
      }
      &:nth-child(3) {
        bottom: 19px;
        transform: rotate(-45deg);
      }
    }
  }
}

.line {
  height: 2px;
  width: 32px;
  top: 18px;
  position: absolute;
  background-color: #000;
  transition: all 300ms ease-in-out, background-color 400ms ease-in 400ms,
    width 400ms ease-in-out 300ms;
  &:first-child {
    top: 10px;
  }
  &:last-child {
    top: initial;
    bottom: 12px;
  }
}
