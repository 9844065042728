@import '~assets/scss/mixins';

.auctionItem {
    background-color: #f7f7f7;
    overflow: hidden;
}
.auctionItemImage {
    height: 260px;
    position: relative;
    img {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
}
.propertyType {
    padding: 8px 12px;
    background-color: #808080;
    color: white;
    font-size: 14px;
    line-height: 16px;
    position: absolute;
    top: 8px;
    left: 8px;
}
.auctionPrice {
    background-color: white;
    padding: 16px 20px;
    font-size: 16px;
    line-height: 24px;
    font-weight: bold;
    color: var(--color-buttons-primary);
    display: inline-block;
    margin-bottom: 16px;
}
.lastBid {
    @extend .auctionPrice;
    font-size: 24px;
    padding: 16px 38px;
    span {
        font-size: 14px;
        line-height: 24px;
        font-weight: 400;
    }
}
.lastBidUser {
    color: black;
}
.lastBidText {
    font-size: 14px;
    line-height: 24px;
    opacity: 0.5;
    color: black;
    font-weight: 400;
}
.userPrice {
    color: #ff4c4c;
}
.auctionItemContent {
    padding: 0 24px 24px;
    text-align: center;
    z-index: 2;
    position: relative;
    margin-top: -28px;

    h3 {
        font-weight: 700;
        margin-bottom: 16px;
        min-height: 60px;
    }
}

.details {
    display: flex;
    gap: 26px;
    justify-content: center;
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 26px;

    i {
        color: var(--color-buttons-primary);
        margin-right: 12px;
        line-height: 12px;
    }
    > div {
        display: flex;
        align-items: center;
    }
}
.timer {
    margin-bottom: 24px;
}
.button {
    font-size: 14px;
}

@include breakpoint('mobile') {
    .auctionItemImage {
        height: 190px;
    }
    .auctionItemContent {
        h3 {
            min-height: auto;
        }
    }
}
