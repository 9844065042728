.root {
    display: flex;
    flex-direction: column;
    align-items: center;
    h2,
    .subtitle {
        width: 100%;
        text-align: left;
    }
}

.subtitle {
    opacity: 0.5;
    margin-top: 17px;
    margin-bottom: 15px;
    font-size: 16px;
}

.double {
    width: 100%;
    justify-content: space-between;
    display: flex;
}

.double-wrapper {
    width: 47%;
}

.eye {
    position: absolute;
    top: 55px;
    right: 20px;
    font-size: 24px;
    cursor: pointer;
}

.password {
    padding-right: 67px !important;
}

.button {
    padding-top: 27px !important;
    padding-bottom: 27px !important;
    margin-top: 40px;
    max-width: 200px;
}

.bottom-text {
    margin-top: 24px;
    font-weight: 500;
    font-size: 16px;
    span {
        padding: 0 4px;
    }
    .gray {
        opacity: 0.35;
    }
}
.genders-options {
    display: flex;
    align-items: center;
    max-width: 430px;
}

.radio-wrapper {
    margin-top: 0;
    input[type='radio'] {
        height: auto;
    }
}
