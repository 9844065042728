@import '~assets/scss/mixins.scss';

.root {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
}
.header {
    height: 120px;
    display: flex;

    .logo {
        padding: 12px 42px 12px 0;
        background-color: #f7f7f7;
        position: relative;
        &:before {
            content: '';
            background-color: #f7f7f7;
            position: absolute;
            top: 0;
            right: 100%;
            width: 500%;
            height: 100%;
            z-index: -1;
        }
    }
    &.whiteHeader {
        color: white;
        .languageSwitcher {
            :global(.switcher-label) {
                color: white;
            }
            :global(.switcher-icon) {
                color: white;
            }
        }
    }
}
.rightSide {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-end;
}
.languageSwitcher {
    :global(.switcher-label) {
        font-size: 14px;
        line-height: 16px;
    }
}
.button {
    width: auto;
    padding: 8px 28px;
    max-height: 40px;
    font-size: 14px;
    line-height: 24px;
    font-weight: 500;
    margin-left: 32px;
}

@include breakpoint('mobile') {
    .header {
        height: 80px;
        .logo {
            padding: 4px 48px 4px 4px;
        }
    }
}
