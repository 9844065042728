@import "~assets/scss/mixins.scss";

.root {
  padding-top: 212px;
  padding-bottom: 100px;
}

.title {
  margin-bottom: 56px;
}

@include breakpoint("tablet") {
  .title{
    margin-bottom: 24px;
  }
  .root{
    padding-top: 104px;
    padding-bottom: 56px;
  }
}

@include breakpoint("mobile") {
  .title{
    text-align: center;
  }
  .root{
    padding-bottom: 64px;
  }
}