.root {
  width: 19px;
  height: 7px;
  background-size: cover;
  svg {
    display: block;
  }
  &.right {
  }
  &.bottom {
  }
  &.left {
  }
}
