@import "~assets/scss/shared.scss";
@import "~assets/scss/mixins.scss";

.root {
  background-color: #000;
  color: #fff;
  padding-bottom: 104px;
  padding-top: 100px;
  &.is-traditional,
  &.is-modern {
    font-family: Manrope, Helvetica, sans-serif;
    font-weight: 200;
  }
  &.is-funky,
  &.is-minimal,
  &.is-classic {
    font-family: GtWalsheim, Helvetica, sans-serif;
    font-weight: 400;
  }
  @include breakpoint("tablet") {
    padding-top: 39px;
    padding-bottom: 43px;
  }
  @include breakpoint("mobile") {
    padding-top: 56px;
    padding-bottom: 69px;
  }
}

.main {
  display: flex;
  @include breakpoint("tablet") {
    flex-wrap: wrap;
    position: relative;
  }
  @include breakpoint("mobile") {
    flex-direction: column;
  }
}

.menu {
  width: 50.8%;
  display: flex;
  flex-wrap: wrap;
  ul {
    display: flex;
    flex-direction: column;
    &:first-child {
      width: 40%;
      li:first-child {
        cursor: default;
        opacity: 0.8 !important;
      }
    }
    &:last-child {
      width: 60%;
    }
  }
  li {
    width: 50%;
    opacity: 0.8;
    margin-bottom: 24px;
    cursor: pointer;
    color: #fff;
    span {
      cursor: pointer;
    }
    &:hover {
      opacity: 1;
      @extend %shared-hover-transition;
    }
  }
  @include breakpoint("tablet") {
    width: 35.8%;
    ul {
      width: 100% !important;
    }
    li {
      width: 100%;
    }
  }
  @include breakpoint("mobile") {
    width: 100%;
  }
}

.social {
  width: 30%;
  ul {
    margin-top: 25px;
  }
  li {
    margin-left: 23px;
    font-size: 22px;
  }
  @include breakpoint("mobile") {
    margin-top: 30px;
  }
}

.payment {
  ul {
    margin-top: 14px;
  }
  li {
    margin-left: 18px;
    font-size: 22px;
    i {
      display: block;
    }
    &.visa {
      font-size: 20px;
    }
    &.mastercard {
      font-size: 20px;
    }
    &.amex {
      font-size: 20px;
    }
  }
  @include breakpoint("tablet") {
    position: absolute;
    left: 35.8%;
    top: 120px;
    z-index: 5;
  }
  @include breakpoint("mobile") {
    position: initial;
    left: initial;
    top: initial;
    margin-top: 46px;
  }
}

.social,
.payment {
  ul {
    display: flex;
    align-items: center;
  }
  li {
    opacity: 0.5;
    @extend %shared-hover-transition;
    cursor: pointer;
    &:first-child {
      margin-left: 0;
    }
    &:hover {
      opacity: 1;
    }
  }
  @include breakpoint("tablet") {
    width: 50%;
  }
  @include breakpoint("mobile") {
    width: 100%;
  }
}

.bottom {
  margin-top: 80px;
  display: flex;
  justify-content: space-between;
  @include breakpoint("tablet") {
    margin-top: 57px;
  }
  @include breakpoint("mobile") {
    flex-direction: column-reverse;
    margin-top: 48px;
  }
}

.made-by,
.stripe span {
  opacity: 0.5;
}

.made-by {
  i {
    font-size: 11px;
    margin: 0 1px;
  }
  @include breakpoint("mobile") {
    text-align: center;
  }
}

.stripe {
  display: flex;
  align-items: center;
  i {
    font-size: 56px;
    margin-left: 14px;
    display: block;
  }
  @include breakpoint("mobile") {
    margin-bottom: 63px;
  }
}
