.root {
    position: fixed;
    top: 0;
    right: 0;
    background: #f2f2f2;
    padding: 29px 24px;
    margin-bottom: 15px;
    color: #000;
    display: flex;
    align-items: center;
    z-index: 9999;
    max-width: 672px;
    transition: transform 0.6s ease-in-out;
    animation: toast-in-right 0.6s ease-in-out;

    &.hide {
        animation: toast-in-left 0.5s ease-out;
    }
}

.body {
    padding-left: 22px;
    h6 {
        font-size: 24px;
    }
    p {
        font-size: 16px;
    }
}

.title {
    margin-bottom: 12px;
}

.success-icon {
    font-size: 50px;
}

.cross-icon {
    position: absolute;
    top: 22px;
    right: 22px;
    cursor: pointer;
}

@keyframes toast-in-right {
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(0);
    }
}

@keyframes toast-in-left {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(100%);
    }
}
