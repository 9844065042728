@import '~assets/scss/mixins.scss';

.root {
    display: flex;
    justify-content: space-between;
}

.product,
.info {
    width: 47.86%;
}

.info {
    position: relative;
}

.label {
    position: absolute;
    left: 40px;
    top: 40px;
    z-index: 1;
    transform: rotate(-16deg);
}

.product-slide {
    vertical-align: middle;
    img {
        width: 100%;
        height: 426px;
        object-fit: cover;
    }
}

.preview {
    position: relative;
    margin-top: 24px;
}

.preview-slider {
    padding-left: 50px;
    padding-right: 50px;
    margin-left: -5px;
    margin-right: -5px;
    :global(.slick-slide) {
        opacity: 0.35;
        padding: 0 5px;
        width: 155px;
        transition: opacity 400ms ease-in-out;
        &:global(.slick-current) {
            opacity: 1;
        }
    }
}

.left-arrow,
.right-arrow {
    position: absolute;
    z-index: 5;
    top: 25px;
}

.left-arrow {
    left: 0;
}

.right-arrow {
    right: 0;
}

.preview-slide {
    cursor: pointer;
    vertical-align: middle;
    img {
        height: 92px;
        width: 100%;
        object-fit: cover;
    }
}

.title {
    margin-bottom: 24px;
}

.uvp {
    color: var(--color-text-salePrive);
    span {
        text-decoration: line-through;
    }
}

.timer {
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    [class*='Timer_title'] {
        // danger
        margin-bottom: 0;
        margin-right: 12px;
    }
    [class*='Timer_value'] {
        // danger
        background-color: var(--color-background-secondary);
        &:after {
            border-right-color: var(--color-background-primary);
        }
        &:before {
            border-left-color: var(--color-background-primary);
        }
    }
}

.infoList {
    color: var(--color-text-heading);
    li:not(:last-child) {
        margin-bottom: 16px;
    }
}

.description ul,
.infoList {
    margin-bottom: 24px;
    li {
        display: flex;
        align-items: center;
        &:before {
            content: '';
            display: block;
            border-radius: 50%;
            margin-right: 17px;
            width: 8px;
            height: 8px;
            background-color: var(--color-buttons-primary);
        }
    }
}

.tabs .description ul {
    margin: 0 0 20px;
    list-style: none;
}

.delivery {
    margin-bottom: 40px;
    opacity: 0.5;
}

.form {
    display: flex;
    margin-bottom: 32px;

    button {
        width: 38%;
        height: 63px;
        margin-left: 3%;
    }
}

.input-wrapper {
    width: 59%;
    margin-top: 0;
    input {
        padding-right: 80px;
    }
}

.currency {
    position: absolute;
    right: 20px;
    top: 20px;
    color: var(--color-text-heading);
}

.privacy {
    span {
        opacity: 0.5;
        padding-right: 4px;
    }
    a {
        text-decoration: underline;
    }
}

.tabs {
    margin-top: 40px;
}

.tabs-menu {
    margin-bottom: 12px;
}

.description {
}

.status {
    margin-bottom: 32px;
}

.status-block:not(:last-child) {
    margin-bottom: 24px;
}

.status-block-top {
    display: flex;
    font-weight: 700;
    margin-bottom: 8px;
    justify-content: space-between;
    color: var(--color-text-heading);
}

.status-block-line {
    height: 8px;
    position: relative;
    background-color: var(--color-background-secondary);
}

.status-block-progress {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
}

.status-block-title {
    display: flex;
    align-items: center;
}

.tooltip {
    margin-left: 7px;
}

.tooltip-icon {
    font-size: 12px;
    cursor: pointer;
    color: #9da6b7;
}

.buy {
    max-width: 273px;
    margin-bottom: 20px;
}

@include breakpoint('tablet') {
    .form {
        display: block;
        button {
            width: 100%;
            margin-left: 0;
        }
    }

    .input-wrapper {
        width: 100%;
        margin-bottom: 24px;
    }
}

@include breakpoint('mobile') {
    .root {
        flex-direction: column;
    }
    .product,
    .info {
        width: 100%;
    }
    .info {
        margin-bottom: 48px;
    }
    .product {
        display: flex;
        flex-direction: column-reverse;
    }
    .buy-box {
        margin-bottom: 48px;
    }
    .status {
        margin-bottom: 56px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    .preview-slider {
        margin-bottom: 65px;
        padding-left: 0;
        padding-right: 0;
        :global .slick-dots {
            left: 0;
            bottom: -50px;
        }
    }
    .right-arrow,
    .left-arrow {
        top: calc(100% + 24px);
    }
    .buy {
        max-width: 100%;
    }
    .tabs-menu {
        overflow-x: auto;
    }
    .tooltip {
        [class*='Tooltip_body'] {
            left: 50%;
            transform: translateX(-50%);
        }
    }
}

.sold {
    margin-bottom: 40px;
    color: var(--color-text-salePrive);
    font-size: 20px;
}
