.root {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.icon {
    font-size: 96px;
    margin-bottom: 40px;
    color: #000;
}

.title {
    text-align: center;
    font-weight: 700 !important;
}

.text {
    text-align: center;
    margin: 24px 0 40px;
    font-size: 16px;
    line-height: 24px;
}

.button {
    max-width: 278px;
    font-weight: 400;
}

.cancel {
    margin-top: 23px;
}
.transparent {
    background: transparent;
    border: 2px solid #000000;
    color: #000000 !important;
}
