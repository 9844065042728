@import "~assets/scss/mixins.scss";

.root {
  margin-top: 237px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: 229px;
  @include breakpoint("mobile") {
    margin-top: 170px;
    padding-bottom: 180px;
  }
}

.wrapper {
  position: relative;
  @include breakpoint("tablet") {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
}

.image {
  width: 100%;
  max-width: 720px;
  position: relative;
  z-index: -1;
}

.text {
  position: absolute;
  bottom: 175px;
  color: #fff;
  opacity: 0.87;
  font-weight: 600 !important;
  left: 50%;
  transform: translateX(-50%);

  @include breakpoint("tablet") {
    position: initial;
    color: #000;
    transform: initial;
    margin-top: -8px;
  }
}

.button {
  margin-top: -3px;
  max-width: 141px;
  @include breakpoint("tablet") {
    margin-top: 50px;
  }
}
