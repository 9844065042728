.root {
    display: inline-block;
    cursor: pointer;
    font-size: 16px;
    &:hover {
        text-decoration: underline;
    }
    //&.is-traditional,
    //&.is-modern {
    //  font-family: Manrope, Helvetica, sans-serif;
    //  font-weight: 700;
    //}
    //&.is-traditional,
    //&.is-modern,
    //&.is-classic {
    //  font-size: 14px;
    //  line-height: 24px;
    //}
    //&.is-traditional {
    //  letter-spacing: 0.5px;
    //}
    //&.is-minimal,
    //&.is-funky,
    //&.is-classic {
    //  font-family: GtWalsheim, Helvetica, sans-serif;
    //  font-weight: 700;
    //}
    //&.is-classic {
    //  letter-spacing: 1px;
    //}
    //&.is-classic,
    //&.is-traditional {
    //  text-transform: uppercase;
    //}
}
