@import '~assets/scss/variables.scss';

.button {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    color: var(--color-background-primary);
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    user-select: none;
    background-color: transparent;
    border: 0;
    outline: none;
    width: 100%;
    padding-right: 37px;
    padding-left: 37px;
    font-size: inherit;
    line-height: 1;
    font-family: inherit;
}
.is-disabled {
    cursor: not-allowed;
    opacity: 0.6;
}
.black {
    background-color: var(--color-buttons-secondary);
}
.primary {
    background-color: var(--color-buttons-primary);
}
.is-medium {
    height: 64px;
}
.is-big {
    height: 72px;
}
