@import '~assets/scss/mixins.scss';

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.3);
    transition: all 0.3s ease-out;
    opacity: 0;
    visibility: hidden;
    z-index: -1;
    height: 100vh;
    overflow-y: auto;
    flex-wrap: wrap;
    &.is-estate {
        background-color: rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(40px);
    }
    &.is-visible {
        visibility: visible;
        opacity: 1;
        z-index: 9999;
    }
}

.modal {
    background-color: var(--color-background-primary);
    position: relative;
    opacity: 0;
    transition: all 0.4s ease-in-out;
    transform: scale(0.95);
    visibility: hidden;
    width: 100%;
    margin: 40px;
    padding: 56px;
    &.is-open {
        opacity: 1;
        visibility: visible;
        transform: scale(1);
    }
    :global .modal-link {
        color: var(--color-buttons-textLink);
        cursor: pointer;
        text-decoration: underline;
        &:hover {
            text-decoration: none;
        }
    }
}

.close {
    width: 28px;
    height: 28px;
    cursor: pointer;
    position: absolute;
    top: 45px;
    right: 46px;
    z-index: 5;
    &:after,
    &:before {
        content: '';
        width: 32px;
        height: 4px;
        border-radius: 4px;
        position: absolute;
        left: 50%;
        top: 50%;
        background-color: var(--color-text-heading);
        transform-origin: 50% 50%;
    }
    &:after {
        transform: translate(-50%, -50%) rotate(45deg);
    }
    &:before {
        transform: translate(-50%, -50%) rotate(-45deg);
    }
}

@include breakpoint('mobile') {
    .modal {
        margin-left: 0;
        margin-right: 0;
        padding-left: 16px;
        padding-right: 16px;
    }
    button[type='submit'] {
        max-width: none;
    }
    .close {
        width: 16px;
        height: 16px;
        right: 24px;
        top: 24px;
        &:after,
        &:before {
            height: 2px;
            width: 20px;
        }
    }
}
