@import '~assets/scss/mixins.scss';

.section {
    overflow: hidden;
}

.title-wrap {
    display: flex;
    align-items: center;
    margin-bottom: 80px;
}

.title {
    flex: 1;
}

.slider {
    position: relative;
    margin-left: -10px;
    margin-right: -10px;
    :global .slick-dots {
        bottom: -50px;
    }
}

.item {
    margin: 0 10px;
}

.arrows {
    display: flex;
}

.arrow {
    margin: 0 12px;
}

.disabled {
    opacity: 0.5;
    pointer-events: none;
}

@include breakpoint('mobile') {
    .item {
        width: 279px;
    }
}
