@import '~assets/scss/variables.scss';

.root {
    display: flex;
    flex-direction: column;
    align-items: center;
    h2 {
        width: 100%;
        text-align: left;
        font-weight: bold;
        font-size: 45px;
        margin-bottom: 19px;
    }
}

.additional {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    font-weight: 200;
    font-size: 16px;
    margin: 25px 0 10px;
}

.subtitle {
    opacity: 0.5;
    text-align: center;
    margin-top: 25px;
}

.button {
    padding-top: 27px !important;
    padding-bottom: 27px !important;
    margin-top: 48px;
    max-width: 200px;
}

.eye {
    position: absolute;
    top: 55px;
    right: 20px;
    font-size: 24px;
    cursor: pointer;
}

.password {
    padding-right: 67px !important;
}

.bottom-text {
    margin-top: 24px;
    font-weight: 500;
    font-size: 16px;
    span {
        padding: 0 4px;
    }
    .gray {
        opacity: 0.35;
    }
}
