.root {
    margin-top: 24px;
    width: 100%;
    display: block;
    position: relative;
}

.label {
    display: flex;
    align-items: center;
    font-weight: 200;
    margin-bottom: 9px;
    font-size: 16px;
}

.icon {
    margin-left: 6px;
}

.subtitle {
    font-size: 14px;
    margin-left: 4px;
    opacity: 0.5;
}
