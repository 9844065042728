@import '~assets/scss/_mixins.scss';

.text {
    margin-top: 46px;
    p {
        font-size: 15px;
        line-height: 24px;
        opacity: 0.5;
    }
}
.more {
    margin-top: 7px;
}

.download {
    cursor: pointer;
    margin-top: 44px;
    font-size: 24px;
    font-weight: 700;
    color: var(--color-buttons-primary);
    display: flex;
    align-items: center;
    width: max-content;

    i {
        margin-right: 22px;
        font-size: 29px;
    }
}

@include breakpoint('mobile') {
    .text {
        margin-top: 26px;
    }
    .download {
        margin-top: 30px;
    }
}
