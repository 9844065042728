@import '~assets/scss/_mixins.scss';
@import '~assets/scss/variables.scss';

.bidsHistoryPasswordModal {
    max-height: calc(100vh - 80px);
    overflow-y: scroll;
    &::-webkit-scrollbar-thumb {
        background-color: var(--color-buttons-primary);
    }
    &::-webkit-scrollbar-track {
        background: rgba(0, 0, 0, 0.05);
    }
    &::-webkit-scrollbar {
        width: 8px;
    }

    @include breakpoint('mobile') {
        padding-top: 13px;
    }
}

.title {
    margin-bottom: 40px;
    padding-right: 20px;
}

.passwordEyeIcon {
    position: absolute;
    top: 50px;
    right: 20px;
    font-size: 24px;
    cursor: pointer;
}

.input {
    padding: 18px 16px;
    font-size: 16px;
    line-height: 24px;
    margin-right: 16px;
    flex: 1;
    display: flex;
}

.buttons {
    margin-top: 20px;
    display: flex;
    gap: 20px;
}

.cancel {
    background-color: var(--color-background-primary);
    color: var(--color-buttons-primary);
    border: 1px solid var(--color-buttons-primary);
}
