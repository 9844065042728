@import '~assets/scss/mixins.scss';

.root {
    background-color: var(--color-background-footer);
    color: var(--color-text-footer);
    padding: 56px 0;
}
.footerContent {
    display: flex;

    .madeBy {
        opacity: 0.5;
        i {
            vertical-align: sub;
        }
    }
}
// LINKS ========================
.links {
    flex: 1;

    ul {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 40px;
        flex-wrap: wrap;
    }
    li {
        &:hover {
            cursor: pointer;
        }
    }
}
// SOCIAL LINKS ========================
.socialLinks {
    display: flex;
    gap: 24px;
    div:hover {
        cursor: pointer;
    }
}

@include breakpoint('mobile') {
    .root {
        padding: 24px 0;
    }
    .footerContent {
        flex-direction: column;
        align-items: center;
        gap: 24px;
        .madeBy {
            order: 3;
        }
    }
    .links{
        order: 2;
        ul {
            gap: 20px 40px;
        }
    }
}
