@import '~assets/scss/mixins.scss';

.item,
.hero {
    i {
        color: $redPrimaryTraditionTemplate;
    }
}

.hero {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    text-transform: uppercase;
    font-weight: 800;
    text-align: center;
    span {
        margin-bottom: 7px;
    }
    i {
        margin-bottom: 6px;
    }
    @include breakpoint('mobile') {
        width: 130px;
        height: 130px;
        line-height: 18px;
    }
}

.item {
    padding: 8px 21px;
    font-weight: 500;
    white-space: nowrap;
    display: flex;
    align-items: center;
    i {
        margin-right: 13px;
        font-size: 23px;
    }
    @include breakpoint('mobile') {
        padding-top: 13px;
        padding-bottom: 13px;
    }
}
