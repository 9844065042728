@import '~assets/scss/mixins.scss';
.section {
    padding: 212px 0 100px;
}
.mainTitle {
    margin-bottom: 80px;
}

.list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
    margin-bottom: 56px;
}

@include breakpoint('tablet') {
    .section {
        padding: 104px 0 64px;
    }
    .mainTitle {
        margin-bottom: 24px;
    }
    .list {
        grid-template-columns: 1fr 1fr;
    }
}

@include breakpoint('mobile') {
    .section {
        padding: 87px 0 48px;
    }
    .list {
        grid-template-columns: 1fr;
        grid-gap: 24px;
        margin-bottom: 32px;
        margin-left: -20px;
        margin-right: -20px;
    }
}

.label {
    font-size: 30px;
    line-height: 36px;
    grid-column: 1 / -1;
}
