.text {
  opacity: 0.5;
  margin-top: 18px;
}

.form {
  margin-top: 24px;
  button[type=submit]{
    height: 72px;
    width: 278px;
  }
}
