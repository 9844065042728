$icons-font: "icons";

@font-face {
    font-family: $icons-font;
    src: url("../fonts/icon/icons.eot?4a77d9c839e75b5f22e3044d62c8fa6a#iefix") format("embedded-opentype"),
url("../fonts/icon/icons.woff2?4a77d9c839e75b5f22e3044d62c8fa6a") format("woff2"),
url("../fonts/icon/icons.woff?4a77d9c839e75b5f22e3044d62c8fa6a") format("woff");
}

i[class^="icon-"]:before, i[class*=" icon-"]:before {
    font-family: icons !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

$icons-map: (
    "amex": "\f101",
    "android": "\f102",
    "apple": "\f103",
    "area": "\f104",
    "arrow-left": "\f105",
    "arrow-right": "\f106",
    "arrow": "\f107",
    "auction-label-star": "\f108",
    "check": "\f109",
    "close-eye": "\f10a",
    "congratulation": "\f10b",
    "cross": "\f10c",
    "delete-filled": "\f10d",
    "download": "\f10e",
    "dropdown-arrow": "\f10f",
    "email": "\f110",
    "eye": "\f111",
    "facebook": "\f112",
    "fin-proof": "\f113",
    "gift": "\f114",
    "hammer-big": "\f115",
    "hammer": "\f116",
    "handbag": "\f117",
    "heart": "\f118",
    "info-fill": "\f119",
    "info-icon": "\f11a",
    "info": "\f11b",
    "instagram": "\f11c",
    "investment": "\f11d",
    "linkedin": "\f11e",
    "location": "\f11f",
    "losing": "\f120",
    "mastercard": "\f121",
    "megaphone-minimal": "\f122",
    "megaphone-traditional": "\f123",
    "not-accepted": "\f124",
    "orders": "\f125",
    "proof-pending": "\f126",
    "rooms": "\f127",
    "settings": "\f128",
    "sign-out": "\f129",
    "snapchat": "\f12a",
    "sticker": "\f12b",
    "stripe-text": "\f12c",
    "success": "\f12d",
    "thumbs-up": "\f12e",
    "tiktok": "\f12f",
    "trash": "\f130",
    "twitter": "\f131",
    "visa": "\f132",
    "wallet": "\f133",
    "warning": "\f134",
    "winning": "\f135",
);

.icon-amex:before {
    content: map-get($icons-map, "amex");
}
.icon-android:before {
    content: map-get($icons-map, "android");
}
.icon-apple:before {
    content: map-get($icons-map, "apple");
}
.icon-area:before {
    content: map-get($icons-map, "area");
}
.icon-arrow-left:before {
    content: map-get($icons-map, "arrow-left");
}
.icon-arrow-right:before {
    content: map-get($icons-map, "arrow-right");
}
.icon-arrow:before {
    content: map-get($icons-map, "arrow");
}
.icon-auction-label-star:before {
    content: map-get($icons-map, "auction-label-star");
}
.icon-check:before {
    content: map-get($icons-map, "check");
}
.icon-close-eye:before {
    content: map-get($icons-map, "close-eye");
}
.icon-congratulation:before {
    content: map-get($icons-map, "congratulation");
}
.icon-cross:before {
    content: map-get($icons-map, "cross");
}
.icon-delete-filled:before {
    content: map-get($icons-map, "delete-filled");
}
.icon-download:before {
    content: map-get($icons-map, "download");
}
.icon-dropdown-arrow:before {
    content: map-get($icons-map, "dropdown-arrow");
}
.icon-email:before {
    content: map-get($icons-map, "email");
}
.icon-eye:before {
    content: map-get($icons-map, "eye");
}
.icon-facebook:before {
    content: map-get($icons-map, "facebook");
}
.icon-fin-proof:before {
    content: map-get($icons-map, "fin-proof");
}
.icon-gift:before {
    content: map-get($icons-map, "gift");
}
.icon-hammer-big:before {
    content: map-get($icons-map, "hammer-big");
}
.icon-hammer:before {
    content: map-get($icons-map, "hammer");
}
.icon-handbag:before {
    content: map-get($icons-map, "handbag");
}
.icon-heart:before {
    content: map-get($icons-map, "heart");
}
.icon-info-fill:before {
    content: map-get($icons-map, "info-fill");
}
.icon-info-icon:before {
    content: map-get($icons-map, "info-icon");
}
.icon-info:before {
    content: map-get($icons-map, "info");
}
.icon-instagram:before {
    content: map-get($icons-map, "instagram");
}
.icon-investment:before {
    content: map-get($icons-map, "investment");
}
.icon-linkedin:before {
    content: map-get($icons-map, "linkedin");
}
.icon-location:before {
    content: map-get($icons-map, "location");
}
.icon-losing:before {
    content: map-get($icons-map, "losing");
}
.icon-mastercard:before {
    content: map-get($icons-map, "mastercard");
}
.icon-megaphone-minimal:before {
    content: map-get($icons-map, "megaphone-minimal");
}
.icon-megaphone-traditional:before {
    content: map-get($icons-map, "megaphone-traditional");
}
.icon-not-accepted:before {
    content: map-get($icons-map, "not-accepted");
}
.icon-orders:before {
    content: map-get($icons-map, "orders");
}
.icon-proof-pending:before {
    content: map-get($icons-map, "proof-pending");
}
.icon-rooms:before {
    content: map-get($icons-map, "rooms");
}
.icon-settings:before {
    content: map-get($icons-map, "settings");
}
.icon-sign-out:before {
    content: map-get($icons-map, "sign-out");
}
.icon-snapchat:before {
    content: map-get($icons-map, "snapchat");
}
.icon-sticker:before {
    content: map-get($icons-map, "sticker");
}
.icon-stripe-text:before {
    content: map-get($icons-map, "stripe-text");
}
.icon-success:before {
    content: map-get($icons-map, "success");
}
.icon-thumbs-up:before {
    content: map-get($icons-map, "thumbs-up");
}
.icon-tiktok:before {
    content: map-get($icons-map, "tiktok");
}
.icon-trash:before {
    content: map-get($icons-map, "trash");
}
.icon-twitter:before {
    content: map-get($icons-map, "twitter");
}
.icon-visa:before {
    content: map-get($icons-map, "visa");
}
.icon-wallet:before {
    content: map-get($icons-map, "wallet");
}
.icon-warning:before {
    content: map-get($icons-map, "warning");
}
.icon-winning:before {
    content: map-get($icons-map, "winning");
}
