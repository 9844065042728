$grayBgTraditionTemplate: #f1f1f1;
$redPrimaryTraditionTemplate: #ff4c4c;
$successTraditionTemplate: #3bb272;

$breakpoints: (
    'mobile': 799px,
    'tablet': 1099px,
) !default;

$fontManrope: Manrope, Helvetica, sans-serif;
$fontGtWalsheim: GtWalsheim, Helvetica, sans-serif;
$transition: all 300ms ease-in-out;
