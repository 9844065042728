@import '~assets/scss/_mixins.scss';

.root {
    position: fixed;
    z-index: 99999;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
    padding: 80px 135px 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease-out;
    &.is-open {
        opacity: 1;
        visibility: visible;
    }
}

.close {
    position: absolute;
    top: 48px;
    right: 48px;
    z-index: 5;
    font-size: 24px;
    color: #fff;
    cursor: pointer;
}

.background {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(40px);
}

.image-wrapper {
    flex-grow: 1;
    position: relative;
    z-index: 5;
    max-height: 660px;
    height: 100%;
    max-width: 1170px;
    width: 100%;
    overflow: hidden;
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
    :global {
        .slick-slider,
        .slick-list,
        .slick-track,
        .slick-slide,
        .slick-slide > div {
            height: 100%;
        }
        .slick-slide {
            z-index: -1;
            &.slick-active {
                z-index: 1;
            }
        }
    }
}

.preview-wrapper {
    margin-top: 40px;
    width: 100%;
    position: relative;
    z-index: 5;
    min-height: 80px;
    display: flex;
    justify-content: center;
}

.preview-slider {
    width: 100%;
    width: 520px;
    :global(.slick-list) {
        padding: 0 !important;
    }
    :global(.slick-current) {
        img {
            border: 4px solid #fff;
        }
    }
}

.preview-slide {
    width: 80px !important;
    cursor: pointer;
    height: 80px;
    object-fit: cover;
    border-radius: 50%;
    overflow: hidden;
    transition: all 0.3s ease-out;
}

.icon-right,
.icon-left {
    position: absolute;
    top: 40px;
    cursor: pointer;
    font-size: 20px;
    color: #fff;
    transition: all 0.3s ease-out;
    i {
        font-size: 22px;
    }
    &.is-disabled {
        opacity: 0.3;
        cursor: default;
    }
}

.icon-right {
    right: -30px;
}

.icon-left {
    left: -30px;
}

@include breakpoint('mobile') {
    .root {
        padding-left: 24px;
        padding-right: 24px;
    }
    .preview-wrapper {
        overflow-x: auto;
        font-size: 0;
        //display: block;
        min-height: 48px;
        //width: auto;
        //margin-right: -24px;
        //margin-left: -24px;
        //padding-left: 24px;
        //max-width: calc(100% + 48px);
        &::-webkit-scrollbar {
            display: none;
        }
        scrollbar-width: none;
        :global {
            .slick-track {
                display: flex;
                justify-content: center;
            }
            .slick-slide {
                margin: 0 4px;
            }
        }
    }
    .preview-slide {
        width: 48px !important;
        height: 48px;
    }
    .icon-left,
    .icon-right {
        display: none;
    }
}
