@import '~assets/scss/variables.scss';
@import '~assets/scss/shared.scss';
@import '~assets/scss/mixins.scss';

.root {
    position: relative;
    margin-left: 40px;
    font-family: Manrope, Helvetica, sans-serif;
    display: flex;
    align-items: center;
    cursor: pointer;
    &.is-open {
        .dropdown {
            top: 70px;
            visibility: visible;
            opacity: 1;
            transform: scale(1);
        }
    }
}

.avatar {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    object-fit: cover;
    display: block;
}

.dropdown {
    position: absolute;
    top: 48px;
    top: 63px;
    right: -7px;
    background-color: var(--color-background-primary);
    width: 200px;
    padding: 20px;
    border: 1px solid #e6e6e6;
    opacity: 0;
    visibility: hidden;
    @extend %shared-hover-transition;
    transform-origin: right top;
    transform: scale(0.95);
    li,
    a {
        display: flex;
        align-items: center;
        @extend %shared-hover-transition;
        &:hover {
            color: var(--color-buttons-primary);
        }
    }

    li {
        margin-top: 13px;
        i {
            font-size: 17px;
            margin-right: 8px;
        }
        &:first-child {
            margin-top: 0;
        }
    }
}

.triangle {
    position: absolute;
    top: -6px;
    right: 20px;
}

.separator {
    width: 100%;
    height: 1px;
    margin-top: 15px !important;
    margin-bottom: 16px;
    background-color: #f2f2f2;
}

.initials {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    width: 48px;
    border-radius: 50%;
    background-color: #000000;
    color: #ffffff;
}
.name {
    margin-left: 12px;
    color: inherit;
    font-size: 14px;
    line-height: 16px;
    font-weight: bold;

    i {
        margin-left: 12px;
        font-size: 6px;
        vertical-align: middle;
    }
}
@include breakpoint('mobile') {
    .root {
        margin-left: 24px;
    }
    .initials {
        width: 40px;
        height: 40px;
        font-size: 0.8em;
    }
}
