.myBids {
    margin-top: 120px;
}
.content {
    padding-top: 80px;
}
.title {
    margin-bottom: 56px;
    &::after {
        content: '';
        display: block;
        width: 80px;
        height: 8px;
        background-color: var(--color-buttons-primary);
        margin-top: 16px;
    }
}
.bidsList {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    margin-bottom: 56px;
}