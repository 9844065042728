.table {
    width: 100%;
    text-align: left;
    th:last-child,
    td:last-child {
        text-align: right;
    }
    th {
        padding: 0 16px 16px 0;
    }
    td {
        padding: 16px 16px 16px 0;
    }

    tr {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
}
.me {
    display: flex;
    color: var(--color-buttons-primary);
    div {
        width: 24px;
        height: 24px;
        color: white;
        background-color: var(--color-buttons-primary);
        border-radius: 50%;
        font-size: 10px;
        line-height: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 8px;
    }
}
