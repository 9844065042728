@import '~assets/scss/_mixins.scss';
.root {
    padding: 88px 0 0;
}
.history {
    background-color: rgba(0, 0, 0, 0.05);
    padding: 56px;
    margin-bottom: 80px;
}
.historyHeader {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
}
.fullHistory {
    color: var(--color-buttons-primary);
    &:hover {
        cursor: pointer;
    }
}

@include breakpoint('mobile') {
    .title {
        margin-bottom: 24px;
    }
    .root {
        background-color: rgba(0, 0, 0, 0.05);
        padding: 24px 0;
    }
    .row {
        padding: 12px 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
    .innerRow {
        display: flex;
        justify-content: space-between;
        & + & {
            color: #808080; // toDo: css variables
            margin-top: 4px;
        }
    }
    .fullHistory {
        text-align: right;
        margin-top: 24px;
    }
}
