@import "~assets/scss/mixins.scss";

.root {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.icon {
  font-size: 96px;
  color: #000;
}

.title {
  margin-top: 40px;
  text-align: center;
}

.button {
  max-width: 278px;
  margin-top: 40px;
}

@include breakpoint('mobile'){
  .title{
    margin-top: 32px;
  }
}