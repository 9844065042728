.noData {
    text-align: center;
    padding: 40px 0;
    i {
        font-size: 74px;
        opacity: 0.2;
        margin-bottom: 40px;
    }
    .title {
        margin-bottom: 16px;
    }
    .text {
        opacity: 0.5;
    }
}
