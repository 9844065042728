@import '~assets/scss/mixins.scss';

.root {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;

    &.is-traditional {
    }
    &.is-modern {
    }
    &.is-minimal {
    }
    &.is-funky {
    }
    &.is-classic {
    }

    &.is-classic,
    &.is-modern {
        /*     color: #fff;
    a {
      color: #fff;
    } */
    }

    &.is-modern,
    &.is-funky,
    &.is-classic {
        .left-part {
            flex-direction: row-reverse;
            justify-content: flex-end;
        }
        .menu {
            margin-left: 65px;
        }
    }
    &.is-modern {
        .left-part {
            width: 100%;
        }
        .right-part {
            width: auto;
        }
        @include breakpoint('mobile') {
            .left-part {
                flex-direction: row;
                justify-content: center;
            }
        }
    }
    &.only-logo {
        .container {
            align-items: center;
            justify-content: center;
        }
    }
}

.container {
    display: flex;
    justify-content: space-between;
    min-height: 166px;
    align-items: center;
    @include breakpoint('tablet') {
        min-height: 100px;
    }
    @include breakpoint('mobile') {
        min-height: 78px;
        padding-left: 90px;
        &.not-authorized {
            padding-left: 0;
        }
    }
}

.left-part,
.right-part {
    display: flex;
    align-items: center;
}

.left-part {
    width: 55%;
    justify-content: space-between;
    @include breakpoint('tablet') {
        width: 57%;
    }
    @include breakpoint('mobile') {
        width: 100%;
        justify-content: center;
    }
}

.menu {
    position: relative;
    ul {
        display: flex;
        li {
            margin-left: 40px;
            line-height: 24px;
            text-transform: uppercase;
            font-weight: 700;
            letter-spacing: 0.5px;
            position: relative;
            &:first-child {
                margin-left: 0;
            }
        }
    }
}

.active-link {
    color: red;
}

.nav-indicator {
    position: absolute;
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 6px solid #000;
    top: 31px;
    transition: left 400ms ease-in-out 100ms;
}

.right-part {
    width: 40%;
    display: flex;
    justify-content: flex-end;
}

.login {
    font-family: Manrope, Helvetica, sans-serif;
    font-weight: 700;
    letter-spacing: 0.5px;
    cursor: pointer;
    font-size: 14px;
    margin-left: 40px;
    margin-right: 41px;
    text-transform: uppercase;
    white-space: nowrap;
}

.register-btn {
    max-width: 141px;
    height: 56px;
}
