@import '~assets/scss/_mixins.scss';
.title {
    margin-bottom: 40px;
}
.historyModal {
    max-height: calc(100vh - 80px);
    overflow-y: scroll;
    &::-webkit-scrollbar-thumb {
        background-color: var(--color-buttons-primary);
    }
    &::-webkit-scrollbar-track {
        background: rgba(0, 0, 0, 0.05);
    }
    &::-webkit-scrollbar {
        width: 8px;
    }
}

@include breakpoint('mobile') {
    .historyModal {
        padding-top: 13px;
    }
}
