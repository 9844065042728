*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  font-family: var(--typography-paragraphBody-fontFamily);
  font-size: calc(var(--typography-paragraphBody-fontSize) * 1px);
  line-height: 1.5;
  background-color: var(--color-background-primary);
  color: var(--color-text-body);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(#000, 0);
  min-width: 320px;
  &.block-scroll {
    overflow: hidden;
  }
}

a {
  text-decoration: none;
  color:  var(--color-buttons-textLink);
}

.container {
  max-width: 1230px;
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
  margin-left: auto;
  margin-right: auto;
  @include breakpoint("tablet") {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.with-overflow {
  .slick-list {
    overflow: initial !important;
    cursor: e-resize;
  }
}

#root{
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
main{
  flex: 1 0 auto;
}