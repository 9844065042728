@import '~assets/scss/mixins.scss';

.section {
}

.container {
    display: flex;
    justify-content: space-between;
}

.left-part {
    width: 35%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 30px 0;
    button {
        max-width: 277px;
        margin-bottom: 21px;
    }
    h2,
    h3 {
        color: var(--color-text-heroAndNavigation);
        margin-bottom: 40px;
    }
    h2,
    h3,
    button {
        &:last-child {
            margin-bottom: 0;
        }
    }
}

:global(.number-value) {
    //    padding: 0px !important;
    //    width: auto !important;
    //    height: auto;
    //    font-weight: bold;
    &::after,
    &::before {
        display: none;
    }
}

:global(.timer-title) {
    margin-bottom: 0px !important;
    color: #000000;
    font-size: 14px;
    line-height: 16px;
}

:global(.unit) {
    padding-top: 0px !important;
    color: rgba(#000000, 0.5);
    font-size: 14px;
    line-height: 16px;
}

.title {
    margin-top: 70px !important;
}

.uvp {
    font-size: 24px;
    line-height: 32px;

    color: var(--color-text-salePrive);
    span {
        text-decoration: line-through;
    }
}

.hero-section__uvp-wrap {
    font-size: 24px;
    line-height: 32px;
}

.img-box {
    position: relative;
    margin-bottom: 16px;
    img {
        width: 100%;
        object-fit: contain;
        display: block;
        height: 344px;
    }
}

.image-wrapper {
    position: relative;
    margin-top: 40px;
    img {
        width: 100%;
    }
}

.sub-text {
    margin-top: 16px;
    font-size: 14px;
    opacity: 0.5;
    color: #000;
    span {
        padding: 0 6px;
    }
}

.right-part {
    padding: 30px 0px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 52%;
}

.label {
    position: absolute;
    z-index: 10;
    transform: rotate(-16deg);
}

.ended-discount {
    font-size: 80px;
    line-height: 80px;
    color: var(--color-text-salePrive);
    font-family: var(--typography-heading-fontFamily), sans-serif;
    margin-bottom: 20px;
    //@include breakpoint("mobile") {
    //  font-size: 48px;
    //  line-height: 48px;
    //  margin-bottom: 30px;
    //  margin-top: 15px;
    //  text-align: center;
    //}
}

.empty-section {
    min-height: 260px;
}

.footer {
    margin-top: 42px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    button {
        width: 240px;
    }
}

@include breakpoint('tablet') {
    .sub-text span {
        display: block;
        font-size: 0;
    }
}
@include breakpoint('mobile') {
    .discount {
        font-size: 21px;
        white-space: nowrap;
    }
    .container {
        flex-direction: column;
    }
}
