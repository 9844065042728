.pendingModal {
    text-align: center;
    i {
        margin-bottom: 44px;
        color: var(--color-buttons-primary);
        font-size: 74px;
    }
    .title {
        margin-bottom: 16px;
    }
    p {
        opacity: 0.5;
        margin-bottom: 40px;
    }
    button {
        width: unset;
        margin: 0 auto;
        min-width: 204px;
    }
}
