@import '~assets/scss/variables.scss';
@import '~assets/scss/shared.scss';

.root {
    display: flex;
    align-items: center;
    cursor: pointer;
    span {
        opacity: 0.5;
        white-space: nowrap;
    }
}

.input {
    width: 0;
    height: 0;
    overflow: hidden;
    opacity: 0;
}

.input:checked + .radio {
    border-color: #000000;
    &:after {
        opacity: 1;
        visibility: visible;
    }
}

.radio-label {
    color: rgba(#000000, 0.8);
}

.radio-label.checked {
    opacity: 1;
    color: #000000;
}

.radio {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 12px;
    border: 1px solid #e6e6e6;
    display: flex;
    align-items: center;
    justify-content: center;
    &:after {
        content: '';
        opacity: 0;
        visibility: hidden;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: var(--color-buttons-primary);
        @extend %shared-hover-transition;
    }
}
