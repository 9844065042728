@import "~assets/scss/mixins.scss";

.root {
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 20px;
}

.body {
  position: relative;
  background-color: var(--color-background-secondary);
  padding: 40px 20px;
}

.status {
  position: absolute;
  padding: 8px 24px;
}

.image-wrapper {
  height: 274px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image {
  max-height: 80%;
  width: auto;
  height: auto;
  max-width: 80%;
}

.invoice {
  margin-top: 30px;
  text-transform: uppercase;
  cursor: pointer;
}
