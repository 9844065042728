.root {
  position: relative;
  padding-bottom: 17px;
  ul {
    display: flex;
  }
  li {
    margin-left: 45px;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;
    transition: opacity 400ms ease-in-out;
    opacity: .5;
    &.is-active {
      color: var(--color-text-heading);
      opacity: 1;
    }
    &:first-child {
      margin-left: 0;
    }
  }
}

.indicator {
  position: absolute;
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 6px solid #000;
  top: 31px;
  transition: left 400ms ease-in-out 100ms;
}
