@import "~assets/scss/variables.scss";
@import "~assets/scss/mixins.scss";

.root {
  padding-top: 212px;
  padding-bottom: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  font-family: Manrope, Helvetica, sans-serif;
}

.image-wrapper {
  position: relative;
  img{
    max-width: 100%;
  }
}

.text {
  width: 100%;
  position: absolute;
  top: 40%;
  transform: translateY(-50%);
  padding: 0 20%;
  text-align: center;
  font-weight: 700 !important;
  color: #eee;
}

.resend-wrapper {
  height: 58px;
  display: flex;
  align-items: center;
}

.resend-text {
  opacity: 0.35;
}

.resend {
  max-width: 300px;
}

.or {
  margin: 18px 0;
  opacity: 0.4;
  font-size: 13px;
}

.button {
  max-width: 200px;
}

.success {
  color: $successTraditionTemplate;
}

@include breakpoint('mobile'){
  .root{
    padding-top: 99px;
    padding-bottom: 48px;
  }
}