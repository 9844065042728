@import '~assets/scss/mixins.scss';
.root {
    margin: 120px 0;
}
.title {
    margin-bottom: 56px;
    &::after {
        content: '';
        display: block;
        width: 80px;
        height: 8px;
        background-color: var(--color-buttons-primary);
        margin-top: 16px;
    }
}
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.sliderBtn {
    color: var(--color-buttons-primary);
    font-size: 22px;
    line-height: 0;
    &:hover {
        cursor: pointer;
    }
    &:first-child {
        margin-right: 52px;
    }
}
.inactive {
    opacity: 0.5;
    pointer-events: none;
}

.slider {
    overflow: hidden;
    :global {
        .slick-slide {
            padding: 0 10px;
        }
        .slick-list {
            margin: 0 -10px;
        }
    }
}

@include breakpoint('mobile') {
    .root {
        padding: 40px 0;
    }
    .title {
        margin-bottom: 24px;
        &::after {
            height: 4px;
        }
    }
    .sliderBtn {
        position: absolute;
        bottom: 0;
    }
    .sliderBox {
        position: relative;
        :global {
            .icon-arrow-left {
                left: 24px;
            }
            .icon-arrow-right {
                right: 24px;
            }
        }
    }
    .slider {
        :global {
            .slick-list {
                margin-bottom: 40px;
            }
            .slick-dots {
                position: static;
                padding: 0 44px;
                li button:before {
                    opacity: 1;
                    font-size: 8px;
                    color: #dedede;
                }
                li.slick-active button:before {
                    color: var(--color-buttons-primary);
                }
            }
        }
    }
}
