@import '~assets/scss/_mixins.scss';

.root {
    margin-bottom: 40px;
}
.bidBox {
    display: flex;
    margin-bottom: 16px;

    .inputBox {
        border: 1px solid var(--color-forms-border);
        padding: 18px 16px;
        font-size: 16px;
        line-height: 24px;
        margin-right: 16px;
        flex: 1;
        display: flex;

        span {
            margin-right: 16px;
            line-height: 24px;
            vertical-align: middle;
        }
    }
    input {
        border: none;
        outline: none;
        font-size: 16px;
        line-height: 24px;
        padding: 0;
        &::placeholder {
            opacity: 0.25;
        }
        &:disabled {
            background: transparent;
        }
    }
    button {
        width: unset;
        padding: 20px 24px;
        font-size: 14px;
        font-weight: 400;
        &:disabled {
            opacity: 0.5;
        }
    }
}

.minimumBid {
    color: #f2a844;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 16px;
    i {
        font-size: 20px;
        margin-right: 10px;
        vertical-align: middle;
    }
}
.rules {
    i {
        font-size: 20px;
        color: #808080;
        margin-right: 10px;
        vertical-align: middle;
    }
    a {
        text-decoration: underline;
    }
}

// Remove arrows from an input that is type=number
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type='number'] {
    -moz-appearance: textfield;
}

@include breakpoint('mobile') {
    .root {
        margin-bottom: 26px;
    }
    .bidBox {
        display: block;
        .inputBox {
            margin: 0 0 18px;
        }
        button {
            width: 100%;
        }
    }
    .minimumBid {
        margin-bottom: 18px;
    }
}
