.root {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.text,
.title {
    text-align: center;
}

.text {
    margin-top: 25px;
    margin-bottom: 40px;
}

.icon {
    font-size: 96px;
    margin-bottom: 40px;
    color: #000;
}

.binding {
    margin-bottom: 50px;
    font-weight: 700;
    color: var(--color-text-salePrive);
}

.button {
    max-width: 280px;
    font-weight: 700;
}
