@import "~assets/scss/mixins.scss";

.root {
  margin-top: 50px;
  margin-bottom: 100px;
}

.mainTitle{
  margin-bottom: 56px;
}

.grid{
  display: grid;
  grid-gap: 20px;
  &:not(:last-child){
    margin-bottom: 56px;
  }
}

.more-wrapper {
  text-align: center;
}

@include breakpoint('mobile'){
  .grid{
    margin-left: -20px;
    margin-right: -20px;
  }
}