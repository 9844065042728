.root {
  width: 40px;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  &:after {
    content: "";
    width: 0;
    height: 0;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
  }
  &.right {
    &:after {
      border-left: 6px solid #000;
    }
  }
  &.left {
    &:after {
      border-right: 6px solid #000;
    }
  }
  &.big {
    width: 72px;
    height: 72px;
    &:after {
      border-top: 6px solid transparent;
      border-bottom: 6px solid transparent;
    }
    &.left {
      &:after {
        border-right: 9px solid #000;
      }
    }
    &.right {
      &:after {
        border-left: 9px solid #000;
      }
    }
  }
}
