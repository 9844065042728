@import "~assets/scss/variables.scss";
@import "~assets/scss/_mixins.scss";

.root {
  position: relative;
  &.is-visible {
    .body {
      visibility: visible;
      opacity: 1;
    }
  }
}

.body {
  position: absolute;
  width: 230px;
  background-color: #fff;
  padding: 24px 0 24px 32px;
  border: 1px solid #e6e6e6;
  box-sizing: content-box;
  visibility: hidden;
  opacity: 0;
  z-index: 20;
  transition: all 300ms ease-in-out;
  li {
    margin-top: 4px;
    font-size: 14px;
    color: #3bb272;
    i {
      margin-right: 6px;
      font-size: 11px;
    }
    &:first-child {
      margin-top: 0;
    }
    &.is-invalid {
      color: $redPrimaryTraditionTemplate;
    }
  }
  ul {
    margin-top: 7px;
  }
}

.title {
  font-size: 14px;
  opacity: 0.35;
}

.triangle {
  position: absolute;
  top: -6px;
  right: 20px;
}

@include breakpoint('mobile'){
  .body{
    padding-left: 24px;
  }
}