@import '~assets/scss/mixins.scss';

.body {
    position: relative;
    height: 100%;
    background-color: var(--color-background-secondary);
    cursor: pointer;
    display: block;
    min-height: 580px;
    &:hover {
        .title {
            text-decoration: underline !important;
        }
    }
    img {
        width: 100%;
        object-fit: cover;
        height: 290px;
        display: block;
    }
}

.title {
    margin-bottom: 16px;
    transition: $transition;
    text-decoration: transparent;
}

.label {
    position: absolute;
    z-index: 1;
    background-color: var(--color-background-primary);
    color: var(--color-text-heading);
}

.uvp {
    color: var(--color-text-salePrive);
    font-weight: 700;
    margin-bottom: 4px;
    span {
        text-decoration: line-through;
    }
}

.discount {
    height: 70px;
    display: flex;
    justify-content: center;
    font-size: 40px;
    align-items: center;
    color: var(--color-text-salePrive);
    font-family: var(--typography-heading-fontFamily);
}

@include breakpoint('mobile') {
    .body {
        img {
            max-height: 360px;
            height: auto;
        }
    }
}
