@import '~assets/scss/mixins.scss';

.root {
    position: relative;
    font-size: 14px;
    color: inherit;
    &.is-open {
        margin-top: 30px;
        padding-bottom: 30px;
        .list {
            opacity: 1;
            top: 42px;
            transform: scale(1);
            pointer-events: initial;
        }
    }
    @include breakpoint('mobile') {
        margin-top: 30px !important;
        padding-bottom: 30px;
    }
}

.current {
    display: flex;
    align-items: center;
    cursor: pointer;
    span {
        font-weight: bold;
        letter-spacing: 0.5px;
        text-transform: uppercase;
    }
}

.icon {
    margin-left: 14px;
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 6px solid #000;
    opacity: 0.25;
}
.customIcon {
    margin-left: 12px;
    font-size: 6px;
    color: inherit;
}
.list {
    position: absolute;
    top: 30px;
    opacity: 0;
    padding: 18px 0 15px;
    left: 50%;
    margin-left: -44px;
    display: flex;
    flex-direction: column;
    //align-items: center;
    align-items: flex-start;
    width: 83px;
    list-style: none;
    text-transform: uppercase;
    transform-origin: top;
    transform: scale(0.95);
    background-color: #fff;
    transition: all 300ms ease-out;
    z-index: 20;
    border: 1px solid #e6e6e6;
    pointer-events: none;

    li {
        cursor: pointer;
        text-align: left;
        font-weight: 600;
        line-height: 26px;
        display: flex;
        align-items: center;
        transition: color 400ms ease-out;
        padding: 0 15px;
        width: 100%;
        img {
            margin-right: 10px;
        }
        &:hover {
            color: var(--color-buttons-primary);
        }
    }
}

.triangle {
    position: absolute;
    top: -6px;
    right: 13px;
}
