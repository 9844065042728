@import '~assets/scss/mixins.scss';

.section {
    padding: 50px 0;
}

.text-wrapper {
    display: flex;
    justify-content: space-between;
}

.left {
    width: 40%;
    &.hasImg {
        align-self: center;
        text-align: center;
    }
}

.text-body {
    width: 49%;
    word-break: break-word;
}

.more {
    margin-top: 24px;
}

@include breakpoint('tablet') {
    .section {
        padding-top: 32px;
    }
    .text-wrapper {
        display: block;
    }
    .left {
        width: 100%;
        margin-bottom: 32px;
    }
    .text-body {
        width: 100%;
    }
    .more {
        margin-top: 24px;
        text-transform: uppercase;
    }
}

@include breakpoint('mobile') {
    .more {
        display: block;
        margin-top: 8px;
    }
}
