.infoModal {
    text-align: center;
    i {
        font-size: 74px;
        margin-bottom: 44px;
    }
    p {
        opacity: 0.5;
        margin-bottom: 40px;
    }
}
.title {
    margin-bottom: 16px;
}
.actions {
    display: flex;
    gap: 16px;
    justify-content: center;
    button {
        width: unset;
        min-width: 204px;
        font-size: 14px;
        font-weight: 400;
    }
    .outlineButton {
        color: #010101;
        background-color: transparent;
        border: 1px solid #010101;
    }
}
