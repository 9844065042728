.root {
  position: relative;
  &:hover {
    .body {
      bottom: 100%;
      opacity: 1;
      visibility: visible;
    }
  }
}

.body {
  position: absolute;
  left: 100%;
  opacity: 0;
  visibility: hidden;
  font-size: 12px;
  line-height: 16px;
  border-radius: 8px;
  background-color: #f3f3f3;
  padding: 13px 15px;
  bottom: calc(100% - 20px);
  z-index: 100;
  color: #797979;
  transition: all 300ms ease-in-out;
}
